import { createSlice } from '@reduxjs/toolkit';

const crawlConfigSlice = createSlice({
  name: 'crawlConfig',
  initialState: {
    isLoading: false,
    selectedCrawlConfig: null,
    crawlConfig: [],
    error: null
  }
});

export const crawlConfigReducer = crawlConfigSlice.reducer;
