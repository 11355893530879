import { createSlice } from '@reduxjs/toolkit';

const settingsSlice = createSlice({
  name: 'settings',
  initialState: {
    isLoading: false,
    settings: [],
    error: null
  }
});

export const settingsReducer = settingsSlice.reducer;
