import { Outlet } from "react-router-dom";
import HeaderComponent from "../components/HeaderComponent";
import { Divider } from "primereact/divider";
import Footer from "../components/FooterComponent";

export default function Root() {
  return (
    <div className="flex min-h-screen flex-col">
      <HeaderComponent className="fixed left-0 top-0 z-20 w-full" />
      <Outlet className="mb-auto h-10" />
      <Footer className="h-10" />

      {/* <footer class="mt-auto w-full bg-white p-4 shadow md:flex md:items-center md:justify-between md:p-6">
        <span class="text-sm sm:text-center">
          © 2024{" "}
          <a href="#" class="hover:underline">
            Techbraid Pty Ltd
          </a>
          . All Rights Reserved.
        </span>
      </footer> */}
    </div>
  );
}
