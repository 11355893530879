import { configureStore, createSlice } from '@reduxjs/toolkit';
import { usersReducer } from './slices/usersSlice';
import { crawlConfigReducer } from './slices/crawlConfigSlice';
import { crawlHistoryReducer } from './slices/crawlHistorySlice';
import { settingsReducer } from './slices/settingsSlice';
import { dashboardReducer } from './slices/dashboardSlice';

export const store = configureStore({
  reducer: {
    dashboardData: dashboardReducer,
    usersData: usersReducer,
    crawlConfigData: crawlConfigReducer,
    crawlHistoryData: crawlHistoryReducer,
    settingsData: settingsReducer
  }
});

// export { store };
