import { createSlice } from '@reduxjs/toolkit';

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState: {
    isLoading: false,
    dashboard: [],
    error: null
  }
});

export const dashboardReducer = dashboardSlice.reducer;
