import { Link } from "react-router-dom";

import { Menubar } from "primereact/menubar";
import { InputText } from "primereact/inputtext";
import { Badge } from "primereact/badge";
import { Avatar } from "primereact/avatar";
import { Divider } from "primereact/divider";

export default function Header() {
  const items = [
    {
      label: "Dashboard",
      icon: "pi pi-home",
    },
    {
      label: "Crawl History",
      icon: "pi pi-star",
    },
    {
      label: "Crawl config",
      icon: "pi pi-search",
    },
    {
      label: "Users",
      icon: "pi pi-envelope",
    },
  ];

  const itemRenderer = (item) => (
    <a className="align-items-center p-menuitem-link flex">
      <span className={item.icon} />
      <span className="mx-2">{item.label}</span>
      {item.badge && <Badge className="ml-auto" value={item.badge} />}
      {item.shortcut && (
        <span className="border-1 surface-border border-round surface-100 ml-auto p-1 text-xs">
          {item.shortcut}
        </span>
      )}
    </a>
  );

  const start = (
    <Link to="/">
      <span className="mx-2">AER-DAS</span>
    </Link>
  );

  const end = (
    <div className="align-items-center flex gap-2">
      <InputText
        placeholder="Search"
        type="text"
        className="w-8rem sm:w-auto"
      />
      <Avatar
        image="https://primefaces.org/cdn/primereact/images/avatar/amyelsner.png"
        shape="circle"
      />
    </div>
  );

  return (
    <div>
      <div className="pb-5 pl-10 pl-4 pt-5 text-left text-3xl text-[#4E5058]">
        <p>
          <span className="font-extrabold text-[#103365]">D</span>ata{" "}
          <span className="font-extrabold text-[#103365]">A</span>ggregation{" "}
          <span className="font-extrabold text-[#103365]">S</span>ystem
        </p>
      </div>
    </div>
  );
}
