import { createSlice } from '@reduxjs/toolkit';

const usersSlice = createSlice({
  name: 'users',
  initialState: {
    isLoading: false,
    selectedUser: null,
    users: [],
    error: null
  },
  reducers: {
    setSelectedUser(state, action) {
      state.selectedUser = action.payload;
    }
  }
});

export const { setSelectedUser } = usersSlice.actions;
export const usersReducer = usersSlice.reducer;
