import { createSlice } from '@reduxjs/toolkit';

const crawlHistorySlice = createSlice({
  name: 'crawlConfig',
  initialState: {
    isLoading: false,
    selectedCrawlHistory: null,
    crawlHistory: [],
    error: null
  }
});

export const crawlHistoryReducer = crawlHistorySlice.reducer;
