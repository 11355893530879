import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Root from './pages/Root';
import DashboardPage from './pages/DashboardPage';
import CrawlHistoryPage from './pages/CrawlHistoryPage';
import CrawlConfigPage from './pages/CrawlConfigPage';
import UsersPage from './pages/UsersPage';
import ReportsPage from './pages/ReportsPage';
import SettingsPage from './pages/SettingsPage';
import LoginPage from './pages/LoginPage';

const router = createBrowserRouter([
  {
    path: '/',
    element: <Root />,
    children: [
      {
        index: true,
        element: <LoginPage />
      },
      {
        index: '/dashboard',
        element: <DashboardPage />
      },
      {
        path: '/crawlhistory/crawlHistoryId',
        element: <CrawlHistoryPage />
      },
      {
        path: '/crawlconfig/:crawlConfigId',
        element: <CrawlConfigPage />
      },
      {
        path: '/users',
        element: <UsersPage />
      },
      {
        path: '/reports',
        element: <ReportsPage />
      },
      {
        path: '/settings',
        element: <SettingsPage />
      }
    ]
  }
]);

function App({ Component, pageProps }) {
  return <RouterProvider router={router} />;
}

export default App;
