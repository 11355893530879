import React from "react";
import { useForm } from "react-hook-form";
const classNames = require("classnames");

export default function LoginPage() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "onTouched" });

  const onSubmit = (data) => {
    console.log(data);
  };

  const emailInputClasses = classNames(
    "block",
    "w-full",
    "rounded-md",
    "border-0",
    "py-1.5",
    "text-gray-900",
    "shadow-sm",
    "ring-1",
    "ring-inset",
    "ring-gray-300",
    "placeholder:text-gray-400",
    "focus:ring-2",
    "focus:ring-inset",
    "focus:ring-blue-600",
    "sm:text-sm",
    "sm:leading-6",
    errors.email && "ring-red-500 focus:ring-red-500",
  );

  const passwordInputClasses = classNames(
    "block",
    "w-full",
    "rounded-md",
    "border-0",
    "py-1.5",
    "text-gray-900",
    "shadow-sm",
    "ring-1",
    "ring-inset",
    "ring-gray-300",
    "placeholder:text-gray-400",
    "focus:ring-2",
    "focus:ring-inset",
    "focus:ring-blue-600",
    "sm:text-sm",
    "sm:leading-6",
    errors.password && "ring-red-500 focus:ring-red-500",
  );

  return (
    <div className="flex min-h-full flex-1 flex-col justify-center bg-opacity-25 bg-[url('/public/static/bg_login.jpg')] bg-cover bg-center bg-no-repeat">
      <div className="flex flex-row">
        <div className="flex-none bg-gradient-to-r from-transparent to-white sm:w-0 md:basis-2/12 lg:basis-3/12 2xl:basis-4/12"></div>
        <div className="grow bg-white p-10 opacity-100 md:basis-8/12 lg:basis-6/12 2xl:basis-4/12">
          <div className="sm:mx-auto sm:w-full sm:max-w-sm">
            <h2 className="text-center text-2xl font-semibold leading-9 tracking-tight text-gray-900">
              Sign in to your account
            </h2>
          </div>
          <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
            <form className="group space-y-6" onSubmit={handleSubmit(onSubmit)}>
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Email address
                </label>
                <div className="mt-2">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    maxLength="100"
                    className={emailInputClasses}
                    {...register("email", {
                      required: "Please enter your username",
                      pattern: {
                        value: /^\S+@\S+\.\S+$/i,
                        message: "Please enter a valid email",
                      },
                      minLength: {
                        value: 4,
                        message: "Please enter a valid email",
                      },
                    })}
                  />
                  <span className="mt-2 text-sm text-red-500">
                    <p role="alert">{errors.email?.message}</p>
                  </span>
                </div>
              </div>

              <div>
                <div className="flex items-center justify-between">
                  <label
                    htmlFor="password"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Password
                  </label>
                  <div className="text-sm">
                    <button className="text-gray-500 hover:font-semibold hover:text-blue-900">
                      Forgot password?
                    </button>
                  </div>
                </div>
                <div className="mt-2">
                  <input
                    id="password"
                    name="password"
                    type="password"
                    autoComplete="current-password"
                    maxLength="50"
                    className={passwordInputClasses}
                    {...register("password", {
                      required: "Please enter your password",
                      minLength: {
                        value: 8,
                        message: "Password is a minimum of 8 characters",
                      },
                    })}
                    aria-invalid={errors.password ? "true" : "false"}
                  />
                  <span className="mt-2 text-sm text-red-500 peer-[&:not(:placeholder-shown):not(:focus):invalid]:block">
                    <p alert="role">{errors.password?.message}</p>
                  </span>
                </div>
              </div>

              <div>
                <button
                  type="submit"
                  className="flex w-full justify-center rounded-md bg-blue-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 group-invalid:pointer-events-none group-invalid:opacity-30"
                >
                  Sign in
                </button>
              </div>
            </form>

            <p className="mt-10 text-center text-sm text-gray-500">
              Not registered?{" "}
              <button className="leading-6 text-gray-500 hover:font-semibold hover:text-blue-900">
                Sign up
              </button>
            </p>
          </div>
        </div>
        <div className="flex-none bg-gradient-to-l from-transparent to-white sm:w-0 md:basis-2/12 lg:basis-3/12 2xl:basis-4/12"></div>
      </div>
    </div>
  );
}
